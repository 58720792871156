
import React from 'react';
import GeneralContent from '../components/general-content';

import PrivacyContent from "../../content/privacy-content.yaml"


export default function Privacy() {
    let header = PrivacyContent.privacyPolicyHeader
    let title = PrivacyContent.privacyPolicyTitle
    let content = PrivacyContent.privacyPolicyContent
    return (
        <GeneralContent header={header} title={title} content={content}></GeneralContent>

    )
}
